@import './src/styles/App.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

.ocean {
  height: 5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.wave {
  background: url('../../images/wavetop.svg') repeat-x;
  background-color: transparent;
  position: absolute;
  bottom: -10px;
  width: 6400px !important;
  height: 14vh;
  animation: wave 30s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite, ease -1.25s infinite;
  transform: translate3d(0, 0, 0);
  z-index: auto;
}
.wave2 {
  background: url('../../images/wavebottom.svg') repeat-x;
  position: absolute;
  width: 6400px !important;
  top: -73vh;
  height: 14vh;
  animation: wave 30s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite, ease -1.25s infinite;
  transform: translate3d(0, 0, 0);
  z-index: auto;
  overflow: auto;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -40px, 0);
  }
  50% {
    transform: translate3d(0, -10px, 0);
  }
}
.endWave {
  display: none;
}

.purple-btn {
  background-color: $color-primary;
  color: #fff;
  justify-content: center;
  align-content: center;
  height: 2.8rem;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
    color: #fff;
  }
}

.secondary-btn {
  background-color: $color-secondary;
  color: $color-primary;
  justify-content: center;
  align-content: center;
  height: 2.8rem;
  border: none;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
}

.purple-btn-xs {
  background-color: $color-primary;
  color: #fff;
  justify-content: center;
  align-content: center;
  border: none;
  margin-right: 0rem;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  cursor: pointer;
  &:hover {
    background-color: #7159c1;
    color: #fff;
  }
}

.no-outline:focus {
  outline: none !important;
}

.t-align-right {
  text-align-last: right !important;
}

.t-align-left {
  text-align-last: left !important;
}

.t-align-center {
  text-align-last: center !important;
}

.position-card {
  position: relative;
  margin-bottom: 0rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 0.5rem;
  padding-left: 0.2rem;
  border-radius: 0.75rem;
  max-height: 14rem;
  background-color: white;
}

.pool-card {
  margin-bottom: 0rem;
  padding: 0.7rem;
  border-radius: 0.5rem;
  max-height: 15rem;
  background-color: white;
}

.white-card {
  margin-bottom: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border-radius: 1rem;
  background-color: white;
}

.deposit-box {
  margin-bottom: 1rem;
  border-radius: 1rem !important;
  background-color: white;
  filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.1));
}

.pool-box {
  margin-bottom: 1rem;
  border-radius: 1rem !important;
  background-color: white;
  filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.1));
}

.card-shadow {
  filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.1));
}

.window {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 1rem !important;
  border: 2px solid;
  height: 30rem;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-left-secondary {
  border-left: 3px solid $color-secondary;
}

.h-fixed {
  max-height: 500rem;
}

.rounded-t {
  border-radius: 10px 10px 0px 0px !important;
}

.rounded-t-l {
  border-radius: 10px 8px 0px 10px !important;
}

.rounded-t-r {
  border-radius: 10px 8px 8px 0px !important;
}

.rounded-less {
  border-radius: 7px 10px 0px 0px !important;
}

.rounded-simm {
  border-radius: 0px 10px !important;
}

.hidden-input {
  background-color: transparent;
  &:focus {
    outline: none;
  }
}

.font-small {
  font-size: 0.85rem;
}

.no-opacity:hover {
  opacity: 1;
}

.selected {
  box-shadow: 0 0 3px 3px #44fed1;
}

.p-sm {
  padding: 0.4rem;
}

.box-shadow {
  box-shadow: 10px 10px 20px 0px #0000001a inset;
}

.text-x {
  font-size: 0.3rem !important;
}
.tvl-pill {
  padding: 6px 1rem 6px 1rem;
}

.btn-secondary:hover {
  box-shadow: 0 0 3px 3px #44fed1;
  background-color: $color-primary !important;
}

.btn-secondary:hover .fill-btn {
  fill: $color-secondary;
}

.btn-secondary:hover .stroke-btn {
  stroke: $color-secondary;
}

.search:focus {
  border: 2px solid $color-secondary !important;
}

.btn-main {
  padding: 6px 1rem 6px 1rem;
  background-color: $color-secondary !important;
  color: $color-primary;
  font-weight: 500;
  font-family: 'Azeret Mono';
}

.btn-main:hover {
  box-shadow: 0 0 5px 1px #44fed1;
  background-color: $color-primary !important;
  color: white !important;
}

.leading-0 {
  line-height: 0rem;
}

.select-network {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../../images/dropdown-arrow.svg');
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 54%;
  z-index: 10000;
}

.arrow-down {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('../../images/dropdown-arrow.svg');
  background-repeat: no-repeat;
  background-position-x: 80%;
  background-position-y: 50%;
  z-index: 10000;
}

.select-network:hover {
  background-image: url('../../images/dropdown-arrow-white.svg');
}

#more > path {
  fill: $color-secondary;
}

#more:hover > path {
  fill: $color-primary;
  box-shadow: 0 0 3px 3px #44fed1;
}

#more > circle {
  fill: $color-primary;
}

#more:hover > circle {
  fill: white;
}

.hint {
  display: none;
  position: absolute;
  background: white;
  bottom: 100%;
  left: 100%;
  min-width: 15rem;
}

.inner-hint {
  padding: 0.5rem;
  font-family: 'Work sans';
  font-weight: 500;
  background-color: #fff;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.aave-info:hover + .hint,
.rebalance-info:hover + .hint,
.oor-info:hover + .hint,
.outofrange-info:hover + .hint,
.aavelogobtn-info:hover + .hint,
.analytics-info:hover + .hint,
.autocompound-info:hover + .hint {
  display: block;
  @media (max-width: 1350px) {
    left: -8rem;
  }
}

.analytics-info:hover + .hint {
  @media (max-width: 1500px) {
    left: -11rem;
    display: block;
  }
}

.__react_component_tooltip {
  width: 250px;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin-top: -50px !important;
  margin-left: -3px !important;
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  z-index: 10000;
  border-top: 2px solid $color-primary;
}

.custom-p {
  padding: 0.5rem 1.8rem 0.5rem 0.8rem;
}

.pagination-container {
  display: flex;
  list-style: none;
  width: auto;
}

.page-link {
  border: none;
  color: $color-primary !important;
  box-shadow: none !important;
}

.page-link:hover {
  background: none;
}

.showMenuNav {
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.hov-primary:hover {
  color: $color-primary !important;
}
.border-color-gray {
  border-color: #525252;
}
